import React from 'react'

export default function Hidden() {
  return (
    <div className="Hidden page">
      <main className="Hidden__main page__content">
        <h1>woah, you found the hidden page</h1>
        <h2>i need to actually think of something to put here</h2>
        <h3>but thanks for visiting. Check back another time!</h3>
      </main>
    </div>
  )
}
